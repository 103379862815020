<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-4" title="Note Validator" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <b-row>
        <b-col md="6">
          <note :note="note"></note>
        </b-col>
        <b-col md="6">
          <note_anggaran :note="koreksi_anggaran"></note_anggaran>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Note from './note.vue'
import note_anggaran from './note_anggaran.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BCol, BRow,
    note_anggaran,
    Note
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-4')
    }
  },
  props: {
    note: {},
    koreksi_anggaran: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>