<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>Balai Bidang</label>
          <v-select v-model="id_balai_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
            @input="LoadProgram(id_balai_bidang)" :options="balai_bidang" />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group>
          <label>Program</label>
          <v-select v-model="id_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_program"
            @input="LoadKegiata()" :options="program" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button variant="info" class="btn-icon mt-2" block @click="loadAll()">
          <feather-icon icon="RefreshCcwIcon" size='15' /> Tampilkan Semua Sub Kegiatan
        </b-button>
      </b-col>
      <b-col md="8">
        <b-form-group>
          <label>Kegiatan</label>
          <v-select v-model="id_subkegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_kegiatan"
            @input="GetLoadData()" :options="setKegiatan" />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BAvatar, BAlert, BButton
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAvatar, BAlert, vSelect, BButton
  },
  data() {
    return {
      id_balai_bidang: '',
      id_kegiatan: {},
      id_subkegiatan: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['LoadProgram', 'LoadKegiata', 'GetLoadData', 'loadAll'],
  methods: {
    LoadKegiata() {
      this.id_subkegiatan = {},
        this.$emit("LoadKegiata", this.id_kegiatan.id_program, this.id_balai_bidang.id_bidang)
    },
    loadAll() {
      this.$emit('loadAll');
    },
    LoadProgram(val) {
      this.id_kegiatan = {};
      this.id_sub_kegiatan = {};
      this.$emit("LoadProgram", val.id_bidang)
    },
    GetLoadData() {
      this.$emit("GetLoadData", this.id_kegiatan.id_program, this.id_balai_bidang.id_bidang, this.id_subkegiatan.id_sub_kegiatan)
    }

  },
  props: {
    balai_bidang: {

    },
    program: {

    },
    kegiatan: {

    }

  },
  computed: {
    setbalai_bidang: {
      get: function () {
        return this.balai_bidang;
      },
      set: function (newValue) {
        return this.balai_bidang = newValue;
      }
    },
    setProgram: {
      get: function () {
        return this.program;
      },
      set: function (newValue) {
        return this.program = newValue;
      }
    },
    setKegiatan: {
      get: function () {
        return this.kegiatan;
      },
      set: function (newValue) {
        return this.kegiatan = newValue;
      }
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style> 