<template>
  <div>
    <b-modal id="modal-edit" title="Form Pengajuan Kegiatan " ok-disabled no-close-on-backdrop size="xl" hide-footer
      hide-header-close no-close-on-esc>
      <b-row>
        <b-col md="11"></b-col>
        <b-col md="1">
          <b-badge style="float: right;" variant="warning" type="submit" class="btn-icon ml-1" @click="close()">
            <feather-icon icon="XIcon" size='15' />
            Keluar
          </b-badge>
        </b-col>
      </b-row>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nama Pekerjaan" label-for="username">
                <validation-provider #default="{ errors }" rules="required" name="Nama Pekerjaan">
                  <b-form-input v-model="input.nama_pekerjaan" :state="errors.length > 0 ? false : null"
                    placeholder="Nama Pekerjaan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Pagu Anggaran" label-for="username">
                    <validation-provider #default="{ errors }" name="Anggaran " rules="required">
                      <CurrencyInput class="form-control" v-model="input.pagu_usulan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Jenis Pekerjaan" label-for="username">
                    <v-select v-model="input.id_pekerjaan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nama_pekerjaan" :options="jenis_pekerjaan" disabled="" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row v-if="input.id_pekerjaan.id_pekerjaan == '7'">
                <b-col md="8">
                  <b-form-group label="Target Output pekerjaan" label-for="basicInput">
                    <b-form-input id="basicInput" placeholder="" v-model="input.target_pekerjaan" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Satuan" label-for="basicInput">
                    <b-form-select v-model="input.satuan" :options="options" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Keterangan" label-for="basicInput">
                <b-form-input aria-invalid="" v-model="input.satuan_target_pekerjaan" />
              </b-form-group>



            </b-col>
          </b-row>
          <hr>
          <b-row v-show="input.id_pekerjaan.id_pekerjaan != '7'">
            <b-col md="12">
              <h5 class="text-primary"><feather-icon icon="GridIcon" size='20' /> Pekerjaan Peningkatan dan Rehabiitasi
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="Ruas Jalan" label-for="basicInput">
                <v-select v-model="input.id_ruas_jalan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="kondisi_ruas_jalan()" label="nama_ruas_jalan" :options="ruas_jalan" disabled="" />
              </b-form-group>
              <b-row>
                <b-col md="8">
                  <b-form-group label="Target Panjang Pengerjaan" label-for="basicInput">
                    <b-form-input id="basicInput" placeholder="" v-model="input.target_pekerjaan" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Satuan" label-for="basicInput">
                    <b-form-select v-model="input.satuan" :options="options" disabled />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Detail Lokasi" label-for="basicInput">
                    <b-form-textarea id="textarea-default" placeholder="Detail Lokasi" rows="2"
                      v-model="input.detail_lokasi" />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="6">
                  <b-form-group label="Lokasi Wilayah" label-for="basicInput">
                    <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                      :options="option" />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </b-col>
            <b-col md="6" v-if="kodisi_jalan != '' && kodisi_jalan != {} && input.id_ruas_jalan.id_ruas_jalan != ''">
              <div v-if="input.id_pekerjaan.id_pekerjaan != '4' && input.id_pekerjaan.id_pekerjaan != '3'">
                <h5>Aspal</h5>
                <b-row>
                  <b-col md="3">Baik<br> <strong class="text-primary">{{ parseFloat(kodisi_jalan.s_aspal_baik) }}
                    </strong>
                    <b-form-group v-if="kodisi_jalan.s_aspal_baik != '0' && kodisi_jalan.s_aspal_baik != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_aspal_baik " v-model="jalan.aspal_baik" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">Sedang<br> <strong class="text-primary">{{ parseFloat(kodisi_jalan.s_aspal_sedang)
                      }}</strong>
                    <b-form-group v-if="kodisi_jalan.s_aspal_sedang != '0' && kodisi_jalan.s_aspal_sedang != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_aspal_sedang " v-model="jalan.aspal_sedang" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">Rusak Ringan<br> <strong class="text-primary">{{
                    parseFloat(kodisi_jalan.s_aspal_rusak_ringan)
                      }}</strong>
                    <b-form-group
                      v-if="kodisi_jalan.s_aspal_rusak_ringan != '0' && kodisi_jalan.s_aspal_rusak_ringan != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_aspal_rusak_ringan "
                        v-model="jalan.aspal_rusak_ringan" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">RusakBerat<br> <strong class="text-primary">{{

                    parseFloat(kodisi_jalan.s_aspal_rusak_berat)

                  }}</strong>
                    <b-form-group
                      v-if="kodisi_jalan.s_aspal_rusak_berat != '0' && kodisi_jalan.s_aspal_rusak_berat != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_aspal_rusak_berat "
                        v-model="jalan.aspal_rusak_berat" />
                    </b-form-group>

                  </b-col>
                </b-row>

                <h5>Beton</h5>
                <b-row>
                  <b-col md="3">Baik<br> <strong class="text-primary">{{ parseFloat(kodisi_jalan.s_beton_baik)
                      }}</strong>
                    <b-form-group v-if="kodisi_jalan.s_beton_baik != '0' && kodisi_jalan.s_beton_baik != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_beton_baik " v-model="jalan.beton_baik" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">Sedang<br> <strong class="text-primary">{{ parseFloat(kodisi_jalan.s_beton_sedang)
                      }}</strong>
                    <b-form-group v-if="kodisi_jalan.s_beton_sedang != '0' && kodisi_jalan.s_beton_sedang != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_beton_sedang " v-model="jalan.beton_sedang" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">Rusak Ringan<br> <strong class="text-primary">{{
                    parseFloat(kodisi_jalan.s_beton_rusak_ringan) + parseFloat(jalan.beton_rusak_ringan)
                  }}</strong>
                    <b-form-group
                      v-if="kodisi_jalan.s_beton_rusak_ringan != '0' && kodisi_jalan.s_beton_rusak_ringan != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_beton_rusak_ringan "
                        v-model="jalan.beton_rusak_ringan" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">RusakBerat<br> <strong class="text-primary">{{
                    parseFloat(kodisi_jalan.s_beton_rusak_berat) }}</strong>
                    <b-form-group
                      v-if="kodisi_jalan.s_beton_rusak_berat != '0' && kodisi_jalan.s_beton_rusak_berat != '0.00'">
                      <b-form-input id="basicInput" placeholder="s_beton_rusak_berat "
                        v-model="jalan.beton_rusak_berat" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="12" class="mt-1">
              <h5 class="text-warning"> <feather-icon icon="GridIcon" size='20' /> File Pendukung Kegiatan </h5>
              <b-row>
                <b-col md="6">
                  <b-form-group label="File  Proposal">
                    <strong v-if="rsEdit.dokumen[0]['file_dokumen'] != ''">
                      <a :href="rsEdit.dokumen[0]['file_dokumen']" target="_blank"><i class="fa fa-download"> </i> File
                        Proposal</a>
                    </strong>
                    <b-form-file v-model="file_proposal" placeholder="Choose a file or drop it here..."
                      ref="file_proposal" id="customFileproposal" @input="uploadFile_proposal"
                      drop-placeholder="Drop file here..." />
                  </b-form-group>
                  <b-form-group label="File  KAK">
                    <strong v-if="rsEdit.dokumen[1]['file_dokumen'] != null">
                      <a :href="rsEdit.dokumen[1]['file_dokumen']" target="_blank"><i class="fa fa-download"> </i> File
                        KAK</a>
                    </strong>
                    <b-form-file v-model="file_kak" placeholder="Choose a file or drop it here..." ref="file_kak"
                      id="customFilekak" @input="uploadFile_kak" drop-placeholder="Drop file here..." />
                  </b-form-group>

                </b-col>

                <b-col md="6">
                  <b-form-group label="File  RAB">
                    <strong v-if="rsEdit.dokumen[2]['file_dokumen'] != null">
                      <a :href="rsEdit.dokumen[2]['file_dokumen']" target="_blank"><i class="fa fa-download"> </i> File
                        RAB</a>
                    </strong>

                    <b-form-file v-model="file_rab" placeholder="Choose a file or drop it here..." ref="file_rab"
                      id="customFilerrab" @input="uploadFile_rab" drop-placeholder="Drop file here..." />
                  </b-form-group>
                  <b-form-group label="File  DED/Lainya">
                    <strong v-if="rsEdit.dokumen[3]['file_dokumen'] != null">
                      <a :href="rsEdit.dokumen[3]['file_dokumen']" target="_blank"><i class="fa fa-download"> </i> File
                        RAB</a>
                    </strong>

                    <b-form-file v-model="file_ded" placeholder="Choose a file or drop it here..." ref="file_ded"
                      id="customFileded" @input="uploadFile_ded" drop-placeholder="Drop file here..." />
                  </b-form-group>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12">
              <b-button variant="primary" type="submit" @click.prevent="validationForm">
                <feather-icon icon="SaveIcon" size='15' /> Submit
              </b-button>

              <b-button variant="warning" type="submit" class="btn-icon ml-1" @click="close()"> <feather-icon
                  icon="XIcon" size='15' />
                Keluar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import CurrencyInput from './CurrencyInput.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow, BFormGroup, BFormInput, BFormFile, BFormTextarea, BBadge, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, vSelect, CurrencyInput,
    BModal, BFormGroup, BFormInput, BFormFile, BFormTextarea, BFormSelect,
    BAlert, BCol, BRow, ValidationProvider, ValidationObserver, BBadge
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      // input: {
      //   id_pekerjaan: { id_pekerjaan: '7', },
      //   id_ruas_jalan: { id_ruas_jalan: '' },
      //   proposal: '',
      //   ded: '',
      //   kak: '',
      //   rab: ''
      // },
      file_proposal: {},
      file_ded: {},
      file_kak: {},
      file_rab: {},

      options: [
        { value: 'Meter', text: 'Meter' },
        { value: 'KM', text: 'Kilo Meter' },
        { value: 'Dokumen', text: 'Dokumen' },
      ],
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["UpdatePengajaun", "kondisi_ruas_jalan"],
  methods: {
    kondisi_ruas_jalan() {
      this.$emit('kondisi_ruas_jalan', this.input.id_ruas_jalan)
    },
    uploadFile_proposal() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file_proposal = this.$refs.file_proposal.files[0];
      let formData = new FormData();
      formData.append('file', this.file_proposal);
      const self = this;
      axios.post('/upload/proposal', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file_proposal = {};
          self.input.proposal = response.data.result.nm_file

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    uploadFile_kak() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file_kak = this.$refs.file_kak.files[0];
      let formData = new FormData();
      formData.append('file', this.file_kak);
      const self = this;
      axios.post('/upload/kak', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file_kak = {};
          self.input.kak = response.data.result.nm_file

        })
        .catch(function (error) {
          console.log(error);
        });
    },

    uploadFile_ded() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file_ded = this.$refs.file_ded.files[0];
      let formData = new FormData();
      formData.append('file', this.file_ded);
      const self = this;
      axios.post('/upload/ded', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file_ded = {};
          self.input.ded = response.data.result.nm_file

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    uploadFile_rab() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file_rab = this.$refs.file_rab.files[0];
      let formData = new FormData();
      formData.append('file', this.file_rab);
      const self = this;
      axios.post('/upload/rab', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file_rab = {};
          self.input.rab = response.data.result.nm_file

        })
        .catch(function (error) {
          console.log(error);
        });
    },


    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (confirm('apakah anda yakin akan update data ini..?')) {
            this.$emit('UpdatePengajaun', this.input, this.jalan);
            this.close();
            this.jalan = {}
            this.input = {
              id_pekerjaan: { id_pekerjaan: '7' },
              id_ruas_jalan: { id_ruas_jalan: '' },
            }
          }

        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-edit');
      this.input = {
        id_pekerjaan: { id_pekerjaan: '7' },
        id_ruas_jalan: { id_ruas_jalan: '' },
      }
    }
  },
  props: {
    rsEdit: {},
    rsDetail: {},
    ruas_jalan: {},
    jenis_pekerjaan: {},
    kodisi_jalan: {}
  },
  computed: {
    input: {
      get: function () {
        return this.rsEdit;
      },
      set: function (newValue) {
        return this.rsEdit = newValue;
      }
    },
    jalan: {
      get: function () {
        return this.rsEdit.kegiatan_kontruksi;
      },
      set: function (newValue) {
        return this.rsEdit.kegiatan_kontruksi = newValue;
      }
    }

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
