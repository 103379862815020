<template>
  <div>
    <b-card>
      <statistik :balai_bidang=balai_bidang :program=program :kegiatan=kegiatan @LoadProgram=LoadProgram
        @LoadKegiata=LoadKegiata @GetLoadData=GetLoadData @loadAll=loadAll>
      </statistik>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="12">
          <tabel_data :rsData=rsData @DetailKegiatan=DetailKegiatan></tabel_data>
        </b-col>
      </b-row>
      <detail-kegiatan :rsDetail=rsDetail :rsDetailSub=rsDetailSub @hapusSubSubKegiatan=hapusSubSubKegiatan
        @LoadNote="LoadNote" @default_kodisi_jalan="default_kodisi_jalan" @loadEdit="loadEdit"></detail-kegiatan>
      <Add_ajuan_kegiatan :rsDetail=rsDetail :ruas_jalan=ruas_jalan :jenis_pekerjaan=jenis_pekerjaan
        @simpanPengajaun=simpanPengajaun :kodisi_jalan=kodisi_jalan @kondisi_ruas_jalan=kondisi_ruas_jalan>
      </Add_ajuan_kegiatan>
      <edit_ajuna_kegiatan :rsEdit="rsEdit" :rsDetail=rsDetail :ruas_jalan=ruas_jalan :jenis_pekerjaan=jenis_pekerjaan
        :kodisi_jalan=kodisi_jalan @kondisi_ruas_jalan=kondisi_ruas_jalan @UpdatePengajaun="UpdatePengajaun">
      </edit_ajuna_kegiatan>
    </b-card>
    <catatan :note="note" :koreksi_anggaran=koreksi_anggaran></catatan>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import tabel_data from './component/tabel.vue'
import statistik from './component/statistik.vue'
import DetailKegiatan from './component/detailKegiatan.vue'
import Add_ajuan_kegiatan from './component/add_ajuan_kegiatan.vue'
import edit_ajuna_kegiatan from './component/edit_ajuna_kegiatan.vue'
import {
  BTabs,
  BTab, BCard, BRow, BCol
} from 'bootstrap-vue'
import Catatan from './component/catatan.vue'
export default {
  components: {
    BTabs, BTab, BCard, tabel_data, BRow, BCol, tabel_data, statistik,
    DetailKegiatan, edit_ajuna_kegiatan, Add_ajuan_kegiatan, Catatan
  },
  data() {
    return {
      id_program: '',
      id_kegiatan: '',
      id_balai_bidang: '',
      rsData: [],
      balai_bidang: [],
      program: [],
      id_bidang: '',
      kegiatan: [],
      rsDetail: {},
      rsDetailSub: [],
      jenis_pekerjaan: [],
      ruas_jalan: [],
      kodisi_jalan: {},
      note: {},
      koreksi_anggaran: {},
      rsEdit: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.bidang_bpj();
    this.get_program();
    this.master_jenis_pekerjaan();
    this.load_ruas_jalan();

  },
  methods: {
    loadEdit(val) {
      this.rsEdit = val
      this.kondisi_ruas_jalan(this.rsEdit.id_ruas_jalan);
    },
    LoadNote(val, val2) {
      this.note = val;
      this.koreksi_anggaran = val2
    },
    async kondisi_ruas_jalan(id_ruas_jalan) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/kondisi_ruas_jalan',
        data: {
          id_ruas_jalan: id_ruas_jalan.id_ruas_jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kodisi_jalan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    default_kodisi_jalan() {
      this.kodisi_jalan = {}
    },
    async master_jenis_pekerjaan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/master_jenis_pekerjaan',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.jenis_pekerjaan = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_ruas_jalan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/load_ruas_jalan',
        data: {
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.ruas_jalan = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },


    simpanPengajaun(val, jalan) {

      this.inputPengajuan(val, jalan)

    },
    async UpdatePengajaun(val, jalan) {
      console.log(val)
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/balai/usulan_kegiatan/update_usulan_kegiatan',
        data: {
          id_usulan_kegiatan: val.id_usulan_kegiatan,
          id_renja: self.rsDetail.id_renja,
          id_balai_bidang: self.rsDetail.id_balai_bidang,
          id_sub_kegiatan: self.rsDetail.id_sub_kegiatan,
          id_pekerjaan: val.id_pekerjaan.id_pekerjaan,
          id_ruas_jalan: val.id_ruas_jalan.id_ruas_jalan,
          tahun_anggaran: val.tahun_anggaran,
          id_jenis_pekerjaan: val.id_jenis_pekerjaan,
          detail_lokasi: val.detail_lokasi,
          nama_pekerjaan: val.nama_pekerjaan,
          target_pekerjaan: val.target_pekerjaan,
          satuan_target_pekerjaan: val.satuan_target_pekerjaan,
          pagu_usulan: val.pagu_usulan,
          satuan: val.satuan,
          kak: val.kak,
          proposal: val.proposal,
          ded: val.ded,
          rab: val.rab,
          jalan: jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.detail_sub_kegiatan_sub();
          self.load_data();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async inputPengajuan(val, jalan) {
      console.log(val)
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/balai/usulan_kegiatan/insert_usulan_kegiatan',
        data: {
          id_renja: self.rsDetail.id_renja,
          id_balai_bidang: self.rsDetail.id_balai_bidang,
          id_sub_kegiatan: self.rsDetail.id_sub_kegiatan,
          id_pekerjaan: val.id_pekerjaan.id_pekerjaan,
          id_ruas_jalan: val.id_ruas_jalan.id_ruas_jalan,
          tahun_anggaran: val.tahun_anggaran,
          id_jenis_pekerjaan: val.id_jenis_pekerjaan,
          detail_lokasi: val.detail_lokasi,
          nama_pekerjaan: val.nama_pekerjaan,
          target_pekerjaan: val.target_pekerjaan,
          satuan_target_pekerjaan: val.satuan_target_pekerjaan,
          pagu_usulan: val.pagu_usulan,
          satuan: val.satuan,
          kak: val.kak,
          proposal: val.proposal,
          ded: val.ded,
          rab: val.rab,
          jalan: jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.rsDetail = response.data.result.result[0];
          self.detail_sub_kegiatan_sub();
          self.load_data();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    loadAll() {
      this.id_program = "";
      this.id_kegiatan = "";
      this.id_balai_bidang = "";
      this.load_data();
    },
    DetailKegiatan(val) {
      this.rsDetail = val
      //
      this.detail_sub_kegiatan_sub()
    },
    hapusSubSubKegiatan(val) {
      this.hapus_sub_sub(val)
      this.detail_sub_kegiatan_sub()
      this.load_data();
    },
    async hapus_sub_sub(id_usulan_kegiatan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/balai/usulan_kegiatan/hapus_data_ususlan_kegiatan',
        data: {
          id_usulan_kegiatan: id_usulan_kegiatan,
          id_renja: self.rsDetail.id_renja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsDetail = response.data.result.result[0];
          self.notification('warning', "Hapus Data", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    GetLoadData(id_program, id_bidang, id_sub_kegiatan) {
      this.id_program = id_program;
      this.id_kegiatan = id_bidang;
      this.id_balai_bidang = id_sub_kegiatan;
      this.load_data();
    },
    LoadProgram(val) {
      this.id_balai_bidang
      this.id_program = val
      this.get_program()
      this.load_data();
    },
    LoadKegiata(id_program, id_balai_bidang) {
      this.id_balai_bidang = id_balai_bidang
      this.id_program = id_program;
      this.get_kegiatan();
      this.load_data();
    },
    //
    async detail_sub_kegiatan_sub(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/detail_sub_kegiatan_sub',
        data: {
          id_renja: self.rsDetail.id_renja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsDetailSub = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_program() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/program',
        data: {
          id_bidang: self.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.program = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/load_data',
        data: {
          id_program: self.id_program,
          id_kegiatan: self.id_kegiatan,
          id_balai_bidang: self.id_balai_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_kegiatan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/kegiatan',
        data: {
          id_balai_bidang: self.id_balai_bidang,
          id_program: self.id_program
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kegiatan = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_program() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/program',
        data: {
          id_bidang: self.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.program = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async bidang_bpj() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/balai/usulan_kegiatan/bidang_bpj',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai_bidang = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>